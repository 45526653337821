.text-secondary {
    color: #3d5d6f;
  }
  
  .h4,
  h4 {
    font-size: 1.2rem;
  }

  h2 {
    color: #333;
  }
  
  .fa,
  .fas {
    font-family: 'FontAwesome';
    font-weight: 400;
    font-size: 1.2rem;
    font-style: normal;
  }
  
  .right-0 {
    right: 0;
  }
  
  .top-0 {
    top: 0;
  }
  
  .h-100 {
    height: 100%;
  }
  
  a.text-secondary:focus,
  a.text-secondary:hover {
    text-decoration: none;
    color: #22343e;
  }
  
  #accordion .fa-plus {
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  }
  
  #accordion a[aria-expanded=true] .fa-plus {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }